<template>
    <main v-if="data" class="pt-12">
        <ContentRenderer :value="data">
            <section
                class="mx-6 lg:mx-12 3xl:mx-0 relative md:sticky md:top-10 md:mt-14 mb-12 z-0 transform-gpu"
            >
                <div class="bg-fjell rounded-t-2xl mx-auto max-w-[1200px] relative">
                    <figure
                        class="absolute top-full -mt-2 -left-4 -right-4 lg:-left-[30px] lg:-right-[30px]"
                    >
                        <img src="~/assets/svg/intro/tavle-bunn.svg" class="w-full" />
                    </figure>

                    <img
                        src="~/assets/svg/intro/la-staa.svg"
                        class="absolute bottom-8 right-12 w-[200px] lg:w-auto"
                    />
                    <img
                        src="~/assets/svg/intro/regnestykke.svg"
                        class="absolute left-[80%] top-[10%] md:left-32 md:top-20 w-[10%]"
                    />
                    <div
                        class="relative pl-16 pr-6 md:px-0 md:left-[35%] md:w-3/5 py-8 pb-32 md:pt-28"
                    >
                        <h2 class="lg:font-heading1 relative">
                            <svg-icon
                                name="krusedull"
                                class="w-5 h-5 lg:w-10 lg:h-10 absolute right-full mr-5 top-4 lg:top-4"
                            />
                            {{ data?.top.title }}
                        </h2>
                        <!-- <h3>Fra tull til gull</h3> -->
                        <p class="lg:font-lead my-8 w-4/5">
                            {{ data?.top.intro }}
                        </p>
                    </div>
                </div>
            </section>
            <div
                class="mx-auto max-w-[1600px] relative md:sticky top-4 md:top-24 z-[2] transform-gpu pointer-events-none"
            >
                <img
                    src="~/assets/svg/intro/sitte-på-mynter.svg"
                    class="ml-6 md:ml-24 xl:ml-40 2xl:ml-60 w-2/5 md:w-1/5 -mb-[35%] md:mb-[20%] lg:-mb-[10%] -mt-[40%] md:-mt-[30%] lg:-mt-[25%]"
                />
            </div>
            <section
                class="relative z-10 mx-4 xl:mx-16 3xl:mx-0 justify-between -mt-[20%] 2xl:-mt-[100px]"
            >
                <div class="mx-auto max-w-[1600px] px-4 lg:px-12 flex flex-col md:flex-row gap-4">
                    <div
                        class="relative bg-white shadow-xl text-fjell p-8 md:p-12 2xl:p-20 rounded-xl md:w-2/3 lg:w-1/2"
                    >
                        <h3 class="font-heading2">{{ data?.section1.title }}</h3>
                        <p class="font-lead my-8">
                            {{ data?.section1.intro }}
                        </p>
                        <a :href="`/${locale}/manus`" class="btn bg-nordlys text-white">
                            {{ data?.section1.link }}
                        </a>
                    </div>
                    <div class="relative md:w-1/3 lg:w-2/5 flex flex-col items-center">
                        <img src="~/assets/svg/intro/mann-med-sparegris.svg" loading="lazy" />
                    </div>
                </div>
            </section>

            <div class="relative z-[3] transform-gpu">
                <section
                    class="overflow-hidden relative -mt-60 py-60 pb-32 xl:-mt-80 xl:pt-80 2xl:pb-60"
                >
                    <div
                        class="absolute bg-vann top-0 h-full w-[500%] md:w-[300%] 2xl:w-[200%] aspect-square left-1/2 -translate-x-1/2 rounded-[100%]"
                    ></div>
                    <div class="mx-auto max-w-[1600px] px-4 lg:px-12 xl:px-24">
                        <div class="relative font-lead max-w-[1200px] space-y-6 my-20">
                            <p v-for="text in data?.section1.texts">
                                {{ text }}
                            </p>
                        </div>
                        <div
                            class="relative max-w-[600px] mx-auto lg:max-w-none flex flex-col lg:flex-row gap-6 md:gap-10 text-fjell"
                        >
                            <div class="flex flex-col gap-6 md:gap-10 lg:w-2/3">
                                <div
                                    class="bg-white rounded-xl p-10 md:py-16 flex flex-col md:flex-row gap-8"
                                >
                                    <figure class="md:w-1/3 max-w-[300px] flex-none">
                                        <NuxtImg
                                            format="webp"
                                            src="/img/forside/fjernkontroll.png"
                                            loading="lazy"
                                            class="w-full"
                                        />
                                    </figure>
                                    <div class="space-y-6 font-sublead">
                                        <h3>{{ data?.section1.box1.title }}</h3>
                                        <p v-for="text in data?.section1.box1.texts">{{ text }}</p>
                                    </div>
                                </div>
                                <div
                                    class="bg-white rounded-xl p-10 md:py-16 flex flex-col md:flex-row gap-8"
                                >
                                    <figure class="md:w-1/3 max-w-[300px] flex-none">
                                        <NuxtImg
                                            format="webp"
                                            src="/img/forside/fjernkontroll-2.png"
                                            loading="lazy"
                                            class="w-full"
                                        />
                                    </figure>
                                    <div class="space-y-6 font-sublead">
                                        <h3>{{ data?.section1.box2.title }}</h3>
                                        <p v-for="text in data?.section1.box2.texts">{{ text }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-white rounded-xl p-10 lg:w-1/3">
                                <Lottie
                                    src="/lottie/highfive.json"
                                    :loop="true"
                                    :width="400"
                                    :height="400"
                                />
                                <h3>{{ data?.section1.box3.title }}</h3>
                                <dl class="flex flex-wrap mt-4">
                                    <template v-for="info in data?.section1.box3.infos">
                                        <dt class="font-bold w-2/5 my-2">{{ info.key }}</dt>
                                        <dd class="w-3/5 my-2 whitespace-pre-wrap">
                                            {{ info.value }}
                                        </dd>
                                    </template>
                                </dl>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="bg-frost-30 -mt-32 pt-32 md:-mt-80 md:pt-80">
                    <section
                        class="flex flex-col lg:flex-row gap-6 md:gap-8 text-fjell mx-auto max-w-[600px] lg:max-w-[1600px] px-4 lg:px-12 xl:px-24 py-20"
                    >
                        <div class="lg:w-1/2">
                            <h2 class="mb-8">{{ data?.section2.title }}</h2>
                            <h4 class="font-normal">
                                {{ data?.section2.intro }}
                            </h4>
                            <figure class="inline-block mt-10">
                                <Lottie
                                    src="/lottie/shrug.json"
                                    :loop="true"
                                    :width="260"
                                    :height="260"
                                />
                            </figure>
                        </div>
                        <div class="lg:w-1/2 space-y-6">
                            <p>
                                {{ data?.section2.list.intro1 }}
                                <a :href="`/${locale}/manus`" class="underline">
                                    {{ data?.section2.list.linkText }}
                                </a>
                                {{ data?.section2.list.intro2 }}
                            </p>
                            <ol class="list-decimal pl-4 space-y-6">
                                <li v-for="text in data?.section2.list.points">
                                    {{ text }}
                                </li>
                            </ol>
                        </div>
                    </section>
                </div>
                <div class="bg-frost-70">
                    <section
                        class="flex flex-col lg:flex-row gap-6 md:gap-8 text-fjell mx-auto max-w-[600px] lg:max-w-[1600px] px-4 lg:px-12 xl:px-24 py-20"
                    >
                        <div class="lg:w-1/2">
                            <h2 class="mb-8">{{ data?.section3.title }}</h2>
                            <h4 class="font-normal">
                                {{ data?.section3.intro }}
                            </h4>
                        </div>
                        <div class="lg:w-1/2">
                            <ol class="list-none space-y-4">
                                <li
                                    v-for="(item, index) in data?.section3.faq"
                                    :key="`item-${index}`"
                                    class="w-full bg-white rounded-xl transition-shadow hover:shadow-lg"
                                >
                                    <button
                                        type="button"
                                        class="p-4 pl-6 flex items-center justify-between w-full text-left"
                                        @click="item.show = !item.show"
                                    >
                                        <strong>{{ item.overskrift }}</strong>
                                        <span
                                            class="rounded-full border border-natt h-9 w-9 flex-none flex items-center"
                                            :class="item.show ? 'rotate-180' : ''"
                                        >
                                            <svg-icon name="chevron" class="w-5 h-5 mx-auto" />
                                        </span>
                                    </button>
                                    <Collapse :when="item.show">
                                        <div class="p-6 pt-0 text-svart" v-html="item.tekst"></div>
                                    </Collapse>
                                </li>
                            </ol>
                        </div>
                    </section>
                </div>
            </div>
        </ContentRenderer>
    </main>
</template>

<script setup lang="ts">
import { Collapse } from 'vue-collapsed';
const { locale } = useI18n();
const { data } = await useAsyncData('data', () =>
    queryContent(`${locale.value}/forside`).findOne()
);
</script>
